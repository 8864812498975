import { render, staticRenderFns } from "./Menu5_KdmLog.vue?vue&type=template&id=4b3d8b38&scoped=true&"
import script from "./Menu5_KdmLog.vue?vue&type=script&lang=js&"
export * from "./Menu5_KdmLog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b3d8b38",
  null
  
)

export default component.exports